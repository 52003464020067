<template>
  <div class="Faq">
    <div class="banner">
      <div class="container">
      </div>
    </div>
    <div class="list pd40">
      <div class="container">
        <div class="headTit">
          <h3><span>FAQ</span></h3>
          <p>Frequently Asked Questions</p>
        </div>
        <div class="jieshao">
          Below you will find answers to some of the most frequently asked questions about ZHUXUE. If you have any further questions, do not hesitate to contact us.
        </div>
        <el-collapse accordion>
          <el-collapse-item v-for="(item,index) in list"
                            :key="index">
            <template #title>
              <h3 class="faqtit">
                <div class="imgbox"><img src="@/assets/qu.png"
                       alt=""></div>
                <div class="sp">{{item.title}}</div>
              </h3>
            </template>
            <div class="asCont">
              <div class="imgbox">
                <img src="@/assets/as.png"
                     alt="">
              </div>
              <div class="txt"
                   v-html="item.content">

              </div>
            </div>
          </el-collapse-item>

        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import axiosApi from "../api/api.js";

export default {
  data () {
    return {
      list: []
    }
  },
  beforeMount () {
    axiosApi(
      'news/2', {}, 'get'
    ).then((res) => {
      this.list = res.data.data
    })
  }
}
</script>

<style lang="scss" scoped>
.banner {
  background: url(~@/assets/neibanner1.jpg) top center no-repeat;
  height: 400px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 4px;
    }
  }
}
.jieshao {
  font-size: 14px;
  color: #545454;
  width: 800px;
  margin: auto;
  margin-bottom: 20px;
  text-align: center;
}
.list {
  .faqtit {
    display: flex;
    .imgbox {
      width: 30px;
      margin-right: 10px;
    }
    .sp {
      width: calc(100% - 30px);
    }
  }
  .asCont {
    display: flex;
    .imgbox {
      width: 30px;
    }
    .txt {
      width: calc(100% - 30px);
      padding-left: 10px;
    }
  }
}
</style>